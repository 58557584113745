.ag-table {
    .table-header {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }
    .p-datatable {
        height: 100%;
        background-color: white;
        tr {
            cursor: pointer;
        }
    }
    .p-sortable-column-icon.pi.pi-fw.pi-sort-alt {
        visibility: hidden;
    }
    td.ag-center-td-content {
        text-align: center !important;
    }
    .p-datatable-wrapper {
        flex-basis: auto !important;
        height: 0 !important;
    }
}
