$main: #3c4962;
$main-300: #5b6f95;
$info: #3b82f6;
$danger: #ff6b6c;
$success: #03a226;
$warning: #feb21f;
$sucess-100: #bbf8c9;
$success-300: #04c82f;
$light-charcoal: #f0f5fb;
$dark-charcoal: #303a4e;
$dark-gray: #464749;
$gray: #6c757d;
$light-gray: #dee2e6;
$light-light-gray: #f5f5f5;
$ligth-blue: #f7faff;
$dark-blue: #404e68;
$gray-step: #757575;

.bg-main {
    background: $main;
}

.surface-main {
    &:hover {
        background: $main;
    }
}

.text-main {
    color: $main;
}

.bg-main-300 {
    background-color: $main-300;
}

.text-main-300 {
    color: $main-300;
}

.bg-info {
    background-color: $info;
}

.bg-danger {
    background-color: $danger;
}

.bg-success {
    background-color: $success;
}

.bg-warning {
    background-color: $warning;
}

.text-info {
    color: $info;
}

.bg-success-300 {
    background-color: $success-300;
}

.text-success-300 {
    color: $success-300;
}
