@import 'colors.scss';

$focused-control-color: #1b74c5;
$invalid-control-border-color: #f44336;
$active-checkbox: #3b82f6;

.ag-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .controls {
        flex-grow: 1;
        align-content: flex-start;
        input,
        .p-dropdown,
        .p-autocomplete {
            height: 41px !important;
            border-radius: 6px;
        }
        .p-dropdown {
            align-items: center;
        }
        label {
            font-size: 13px;
            font-weight: 400;
        }
        .ng-invalid:focus {
            border-color: $focused-control-color !important;
        }
        .ng-invalid.ng-dirty:not(:focus) {
            border-color: $invalid-control-border-color !important;
        }
        input.p-filled ~ label,
        input:focus ~ label,
        p-dropdown:focus ~ label,
        .p-inputwrapper-filled ~ label,
        .p-inputwrapper-focus ~ label {
            color: $info;
            font-size: 10px;
            font-weight: 600;
            line-height: 10px;
        }
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding: 0;
        button {
            width: unset;
            height: 38px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            padding: 10.5px 17.5px;
            border-radius: 6px;
        }
        button.button-info {
            background-color: $info;
        }
        button.button-outlined-info {
            border-color: $info;
            color: $gray;
        }
    }

    .p-checkbox .p-checkbox-box {
        border-radius: 6px;
        width: 22px;
        height: 22px;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: $active-checkbox;
        background: $active-checkbox;
    }
}
